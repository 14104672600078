import { withAuthenticationRequired } from '@auth0/auth0-react';

import AppFrame from '@/components/AppFrame';
import DomainsList from '@/components/DomainsList';

export function DomainsIndex({ organizationId }: { organizationId: string }) {
  return (
    <AppFrame organizationId={organizationId}>
      <DomainsList organizationId={organizationId} />
    </AppFrame>
  );
}

export default withAuthenticationRequired(DomainsIndex);
