import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import DomainsDetailActions from '@/components/DomainsDetailActions';
import IconDropdownMenu from '@/components/core/IconDropdownMenu';
import PageError from '@/components/core/PageError';
import PageLoading from '@/components/core/PageLoading';
import UndrawDomainNames from '@/assets/svgs/undraw-domain-names.svg';

// eslint-disable-next-line import/no-extraneous-dependencies
import { LandingDomain20211012Domain } from '@clients/landing-domain';

const defaultProps = {};

type ListStateProps = {
  isMenuOpen: boolean;
};

type DomainsListViewProps = {
  domains: Array<LandingDomain20211012Domain>;
  error: unknown;
  listState: Record<string, ListStateProps>;
  loading: boolean;
  organizationId: string | number;
  setListState: Function;
} & typeof defaultProps;

export function DomainsListView({
  domains,
  error,
  listState,
  loading,
  organizationId,
  setListState,
}: DomainsListViewProps) {
  const { t } = useTranslation();
  const setMenuStateById = (id: string, isOpen: boolean) => {
    setListState({
      ...listState,
      [id]: {
        ...(listState[id] ? listState[id] : {}),
        isMenuOpen: isOpen,
      },
    });
  };
  return (
    <Box>
      <Box mb={3}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            color="inherit"
            component={GatsbyLink}
            to={`/organizations/${organizationId}`}
            underline="hover"
          >
            {organizationId}
          </Link>
          <Typography color="text.primary">{t(`domains.domains`)}</Typography>
        </Breadcrumbs>
        <Stack
          direction={{ xs: `row`, sm: `row` }}
          justifyContent="space-between"
        >
          <Typography
            variant="h1"
            color="inherit"
            sx={{ mb: { xs: 1, sm: 0 } }}
          >
            {t(`domains.domains`)}
          </Typography>
          <Box>
            <Button
              to={`/organizations/${organizationId}/domains/create`}
              variant="contained"
              component={GatsbyLink}
              startIcon={<AddIcon />}
            >
              {t(`domains.add-domain`)}
            </Button>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item md={12}>
            <TableContainer component={Paper} elevation={0}>
              <Table aria-label={t(`domains.domains`)} size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell>{t(`domains.table-header.name`)}</TableCell>
                    <TableCell align="right">
                      {t(`domains.table-header.actions`)}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={4} sx={{ borderBottom: `unset` }}>
                        <PageLoading>{t(`domains.loading`)}</PageLoading>
                      </TableCell>
                    </TableRow>
                  ) : error ? (
                    <TableRow>
                      <TableCell colSpan={4} sx={{ borderBottom: `unset` }}>
                        <PageError>
                          {error.message || t(`domains.error`)}
                        </PageError>
                      </TableCell>
                    </TableRow>
                  ) : domains && domains.length ? (
                    domains.map((domain) => (
                      <TableRow key={domain.id}>
                        <TableCell
                          style={{ width: 500 }}
                          component="th"
                          scope="row"
                        >
                          <Link
                            component={GatsbyLink}
                            to={`/organizations/${organizationId}/domains/${domain.name}`}
                          >
                            {domain.name}
                          </Link>
                        </TableCell>
                        <TableCell align="right" style={{ width: 100 }}>
                          <IconDropdownMenu
                            aria-label={t(`common.menu.more-actions`)}
                            disabled={false}
                            onClick={() => setMenuStateById(domain.id, true)}
                            onClose={() => setMenuStateById(domain.id, false)}
                            icon={<MoreVertIcon />}
                            isOpen={listState[domain.id]?.isMenuOpen}
                          >
                            <DomainsDetailActions
                              domainId={domain?.id}
                              isOpen={listState[domain.id]?.isMenuOpen}
                              onClose={() => setMenuStateById(domain.id, false)}
                              organizationId={organizationId}
                            />
                          </IconDropdownMenu>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colspan={4} sx={{ borderBottom: `unset` }}>
                        <Box
                          sx={{
                            maxWidth: 640,
                            my: 7,
                            mx: `auto`,
                            textAlign: `center`,
                          }}
                        >
                          <UndrawDomainNames height="200" width="200" />
                          <Typography variant="h5">
                            {t(`domains.zero-state.no-sites`)}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ color: `text.secondary`, mb: 2 }}
                          >
                            {t(`domains.zero-state.add-one-now`)}
                          </Typography>
                          <Box>
                            <Button
                              component={GatsbyLink}
                              color="secondary"
                              startIcon={<AddIcon />}
                              to={`/organizations/${organizationId}/domains/create`}
                            >
                              {t(`domains.add-domain`)}
                            </Button>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

DomainsListView.defaultProps = defaultProps;

export default DomainsListView;
