import { useTranslation } from 'react-i18next';
import { Link as GatsbyLink } from 'gatsby';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

const defaultProps = {};

type DomainsDetailActionsViewProps = {
  domainId: string | number;
  onClose: any;
  organizationId: string | number;
} & typeof defaultProps;

export function DomainsDetailActionsView({
  domainId,
  onClose,
  organizationId,
}: DomainsDetailActionsViewProps) {
  const { t } = useTranslation();
  return (
    <>
      <MenuItem
        component={GatsbyLink}
        disableRipple
        onClick={onClose}
        to={`/organizations/${organizationId}/domains/${domainId}/edit`}
      >
        <ListItemIcon>
          <EditIcon />
        </ListItemIcon>
        <ListItemText>{t(`domains.actions.edit-domain`)}</ListItemText>
      </MenuItem>
      <MenuItem
        component={GatsbyLink}
        disableRipple
        onClick={onClose}
        to={`/organizations/${organizationId}/domains/${domainId}/delete`}
      >
        <ListItemIcon>
          <DeleteIcon sx={{ color: `red.A700` }} />
        </ListItemIcon>
        <ListItemText primaryTypographyProps={{ color: `error` }}>
          {t(`domains.actions.remove-domain`)}
        </ListItemText>
      </MenuItem>
    </>
  );
}

DomainsDetailActionsView.defaultProps = defaultProps;

export default DomainsDetailActionsView;
