import { useState } from 'react';
import { useApi } from '@/hooks/use-api';
import api from '@/utilities/api';

import DomainsListView from '@/components/DomainsList/DomainsListView';

const defaultProps = {};

type DomainsListContainerProps = {
  organizationId: string | number;
} & typeof defaultProps;

export function DomainsListContainer({
  organizationId,
}: DomainsListContainerProps) {
  const options = {};
  const params = [organizationId];
  const { data, error, loading } = useApi(
    api.domains.list.bind(api.domains),
    params,
    options,
  );
  const [listState, setListState] = useState<any>({});
  return (
    <DomainsListView
      domains={data?.domains}
      listState={listState}
      loading={loading}
      error={error}
      setListState={setListState}
      organizationId={organizationId}
    />
  );
}

DomainsListContainer.defaultProps = defaultProps;

export default DomainsListContainer;
